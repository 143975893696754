<script>
import { mapActions, mapState } from 'vuex';
// import MarzipanoTable from '../components/MarzipanoTable.vue'

export default {
    // components: {
    //     MarzipanoTable
    // },
    data() {
        return {
            states: [
                {
                    id: 1,
                    name: 'Disponible',
                },
                {
                    id: 2,
                    name: 'Reservado',
                },
                {
                    id: 3,
                    name: 'Vendido',
                },
            ],
            lotesAux: [],
            selectedState: 0,
            manzanaSeleccionada: 'all',
            manzanas: [],
        }
    },


    computed: {
        ...mapState(['project', 'mapboxProjectData', 'marzipanoProjectData', 'marzipanoScenes']),
        item() {
            return this.data
        }

    },
    methods: {
        ...mapActions(['fetchProject', 'fetchLotesData']),
        filtrar(states) {
            const { stateId, block } = states
            if (this.project.lotizationType == 'marzipano') {
                if (this.marzipanoProjectData == null) {
                    return
                }
                this.filtrarMarzipanoData(states)
            }

            if (this.project.lotizationType == 'mapbox') {
                if (this.mapboxProjectData == null) {
                    return
                }
                this.filtrarMapboxData(stateId)
            }

        },
        filtrarMarzipanoData(states) {
            console.log(states)
            try {
                const { stateId, block } = states
                if (stateId != null) {
                    this.selectedState = stateId;
                    // this.savePreferences({ stateIdSelected: stateId })
                }
                if (block != null) {
                    this.manzanaSeleccionada = block
                    // this.savePreferences({ blockSelected: block })
                }

                function compare(a, b) {
                    if (a.manzana < b.manzana) {
                        return -1;
                    }
                    if (a.manzana > b.manzana) {
                        return 1;
                    }
                    return 0
                }

                this.lotesAux = this.marzipanoProjectData.filter((item) => {




                    return (this.selectedState == 0 ? true : this.selectedState == item.state) &&
                        (this.manzanaSeleccionada == 'all' ? true : this.manzanaSeleccionada == item.manzana)
                        ;
                }).sort(compare);

            } catch (e) {
                console.log(e);
            }
        },
        getArrayManzanas() {
            let arrayManzanas = [];
            for (var item of this.lotesAux) {
                arrayManzanas.push(item.manzana)
            }
            this.manzanas = new Set(arrayManzanas);

        },
        filtrarMapboxData(stateId) {
            try {
                this.selectedState = stateId;

                function compare(a, b) {
                    if (a.properties.lote < b.properties.lote) {
                        return -1;
                    }
                    if (a.properties.lote > b.properties.lote) {
                        return 1;
                    }
                    return 0
                }

                this.lotesAux = this.maxpboxProjectData.filter((item) => {

                    if (this.selectedState == 0) {
                        return true;
                    }
                    return item.properties.state == this.selectedState;
                }).sort(compare);

            } catch (e) {
                console.log(e);
            }
        },
        getNameStateByStateId(id) {
            if (id == '1' || id == 1) {
                return 'Disponible';
            }
            else if (id == '2' || id == 2) {
                return 'Reservado';
            }
            else if (id == '3' || id == 3) {
                return 'Vendido';
            }
            else {
                return 'Todos';
            }

        },
        openTable(evt, zoneName) {
            // Declare all variables
            var i, tabcontent, tablinks, sectionContent;

            // Get all elements with class="tabcontent" and hide them
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            // document.getElementById(zoneName).style.display = "block";
            sectionContent = document.getElementsByClassName(zoneName);
            for (i = 0; i < sectionContent.length; i++) {
                sectionContent[i].style.display = "block";
            }

            evt.currentTarget.className += " active";
        },
        savePreferences(preferences) {
            const { blockSelected, stateIdSelected } = preferences

            if (blockSelected != null) {
                localStorage.setItem("block_selected", JSON.stringify(blockSelected))
            }

            if (stateIdSelected != null) {
                localStorage.setItem("state_id_selected", JSON.stringify(stateIdSelected))
            } console.log('')

        },
        loadPreferences() {
            if (localStorage.getItem("block_selected")) {
                this.manzanaSeleccionada = JSON.parse(localStorage.getItem("block_selected"))
            }

            if (localStorage.getItem("state_id_selected")) {
                this.selectedState = JSON.parse(localStorage.getItem("state_id_selected"))
            }
        },
        // async init() {
        //     await this.fetchProject({ projectId: this.$route.params.projectId })
        //     this.lotizationType = this.project.lotizationType
        // }


    },
    async created() {
        await this.fetchProject({ projectId: this.$route.params.projectId })
        await this.fetchLotesData({ projectId: this.$route.params.projectId })
        this.filtrar({ stateId: 0 })
        this.getArrayManzanas()
        // this.loadPreferences()
        // this.filtrar({ stateId: this.selectedState })
    }

}
</script>

<template>
    <div class="container">
        <div v-if="project != null">
            <h2 class="pt-2">{{ project.name }}</h2>
            <span>{{ project.description }}</span>
            <br>
            <span>{{ project.client }}</span>
        </div>
        <br>
        <router-link class="btn btn-dark" :to="{
            name: 'ProjectSettings',
            params: {
                projectId: this.$route.params.projectId,
            }
        }"> Configuración de contacto </router-link>


        <table class="table" ref="testHtml">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Manzana</th>
                    <th scope="col">Lote</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Acción</th>
                </tr>
            </thead>
            <div>

            </div>
            <tbody>
                <tr>
                    <td></td>
                    <td>
                        <div class="btn-group">
                            <div class="dropdown-center filter-by">
                                <button class="btn btn-light dropdown-toggle btn-square" type="button" id="dropdownMenu2"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span v-if="manzanaSeleccionada == 'all'">Todas</span>
                                    <span v-else>{{ manzanaSeleccionada }}</span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                                    <button class="dropdown-item" type="button"
                                        @click="filtrarMarzipanoData({ block: 'all' })">TODAS</button>
                                    <button v-for="item in manzanas" :key="item" class="dropdown-item" type="button"
                                        @click="filtrarMarzipanoData({ block: `${item}` })">{{ item.toUpperCase()
                                        }}</button>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <div class="btn-group">
                            <div class="dropdown-center filter-by">
                                <button class="btn btn-light dropdown-toggle btn-square" type="button" id="dropdownState"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span v-if="selectedState == 0">TODOS</span>
                                    <span v-else>
                                        {{ getNameStateByStateId(this.selectedState).toUpperCase() }}
                                    </span>
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownState">
                                    <button class="dropdown-item" type="button"
                                        @click="filtrarMarzipanoData({ stateId: 0 })">TODOS</button>
                                    <button v-for="item in states" :key="item" class="dropdown-item" type="button"
                                        @click="filtrarMarzipanoData({ stateId: `${item.id}` })">
                                        <span
                                            :class="{ 'bg-danger badge rounded': item.id == 3, 'bg-warning badge rounded': item.id == 2, 'bg-success badge rounded': item.id == 1 }">
                                            &nbsp&nbsp;</span>
                                        {{ item.name.toUpperCase() }}</button>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>

                <tr v-if="lotesAux.length == 0">
                    <td class="text-center" colspan="5">
                        Ningún resultado coincide con los filtros de búsqueda.
                    </td>
                </tr>

                <tr v-for="(item, index) in lotesAux" :key="index">
                    <td>{{ item.id }}</td>

                    <td>{{ item.manzana }}</td>
                    <td>{{ item.lote }}</td>
                    <td>
                        <span
                            :class="{ 'bg-danger badge rounded': item.state == '3', 'bg-warning badge rounded': item.state == '2', 'bg-success badge rounded': item.state == '1' }">
                            &nbsp&nbsp;</span>
                        {{ getNameStateByStateId(item.state).toUpperCase() }}
                    </td>
                    <td>
                        <router-link class="btn btn-secondary c-btn-bg ml-2 btn-sm" :to="{
                            name: 'Editar',
                            params: {
                                projectId: this.$route.params.projectId,
                                lotizationType: this.project.lotizationType,
                                id: item.id
                            }
                        }">
                            Editar
                        </router-link>
                    </td>
                </tr>


            </tbody>
        </table>



        <!-- <table v-if="project.lotizationType == 'mapbox'" class="table" ref="testHtml">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col">#</th>
                                                                                                        <th scope="col">Manzana</th>
                                                                                                        <th scope="col">Lote</th>
                                                                                                        <th scope="col">Estado</th>
                                                                                                        <th scope="col">Acción</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <div>

                                                                                                </div>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td>
                                                                                                            <div class="btn-group">
                                                                                                                <div class="dropdown-center filter-by">
                                                                                                                    <button class="btn btn-light dropdown-toggle btn-square" type="button"
                                                                                                                        id="dropdownState" data-toggle="dropdown" aria-haspopup="true"
                                                                                                                        aria-expanded="false">
                                                                                                                        <span v-if="selectedState == 0">TODOS</span>
                                                                                                                        <span v-else>
                                                                                                                            {{ getNameStateByStateId(this.selectedState).toUpperCase() }}
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                    <div class="dropdown-menu" aria-labelledby="dropdownState">
                                                                                                                        <button class="dropdown-item" type="button"
                                                                                                                            @click="filtrarMapboxData(0)">TODOS</button>
                                                                                                                        <button v-for="item in states" :key="item" class="dropdown-item" type="button"
                                                                                                                            @click="filtrarMapboxData(`${item.id}`)">
                                                                                                                            <span
                                                                                                                                :class="{ 'bg-danger badge rounded': item.id == 3, 'bg-warning badge rounded': item.id == 2, 'bg-success badge rounded': item.id == 1 }">
                                                                                                                                &nbsp&nbsp;</span>
                                                                                                                            {{ item.name.toUpperCase() }}</button>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td></td>
                                                                                                    </tr>

                                                                                                    <tr v-if="lotesAux.length == 0">
                                                                                                        <td class="text-center" colspan="5">
                                                                                                            Ningún resultado coincide con los filtros de búsqueda.
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr v-for="(item, index) in lotesAux" :key="index">
                                                                                                        <td>{{ item.id }}</td>

                                                                                                        <td>{{ item.properties.manzana }}</td>
                                                                                                        <td>{{ item.properties.lote }}</td>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                :class="{ 'bg-danger badge rounded': item.properties.state == '3', 'bg-warning badge rounded': item.properties.state == '2', 'bg-success badge rounded': item.properties.state == '1' }">
                                                                                                                &nbsp&nbsp;</span>
                                                                                                            {{ getNameStateByStateId(item.properties.state).toUpperCase() }}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <router-link class="btn btn-secondary c-btn-bg ml-2 btn-sm" :to="{
                                                                                                                name: 'Editar',
                                                                                                                params: {
                                                                                                                    projectId: this.$route.params.projectId,
                                                                                                                    lotizationType: this.project.lotizationType,
                                                                                                                    id: item.id
                                                                                                                }
                                                                                                            }">
                                                                                                                Editar
                                                                                                            </router-link>
                                                                                                        </td>
                                                                                                    </tr>


                                                                                                </tbody>
                                                                                            </table> -->

    </div>
</template>

<style>
@media print {
    .filter-by {
        display: none;
    }
}

/* Style the tab */
.tab {
    display: flex;
    /* overflow: hidden;*/
    overflow-x: auto;
    /* border: 1px solid #ccc;*/
    background-color: #F2F7F5;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    width: 200px;
    background-color: inherit;
    float: left;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #ccc;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-weight: 600;
}

/* Change background color of buttons on hover */
.tab button:hover {
    color: #005F3C;
    transition: 0.3s;
}

/* Create an active/current tablink class */
.tab button.active {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    background-color: #F2F7F5;
    color: #005F3C;
}


.expanded {
    flex-grow: 1;
    align-items: flex-end;
    border-bottom: 1px solid #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-radius: 0 0 5px 5px;
}

.tabcontent {
    animation: fadeEffect 1s;
    /* Fading effect takes 1 second */
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.custom-table {
    border: none;
}
</style>